import { render, staticRenderFns } from "./modalSocial.vue?vue&type=template&id=52a59382&scoped=true&"
import script from "./modalSocial.vue?vue&type=script&lang=js&"
export * from "./modalSocial.vue?vue&type=script&lang=js&"
import style0 from "./modalSocial.vue?vue&type=style&index=0&id=52a59382&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a59382",
  null
  
)

export default component.exports